const things = [
  "tomato",
  "magnet",
  "yoga mat",
  "paperclip",
  "coffee mug",
  "vacuum",
  "toothbrush",
  "filter",
  "drivers license",
  "remote control",
  "iPad stand",
  "cat tree",
  "hair brush",
  "soccer team",
  "soda can",
  "pill",
  "robot",
  "branch",
  "candle",
  "goggles",
  "earbuds",
  "robot",
  "skeleton",
  "match",
  "computer",
];

export default things;
